<template>
  <div class="mExplain">
    <img src="https://hexiaoxiang-xcx.oss-cn-hangzhou.aliyuncs.com/web_static/explain.png" class="explainImg">
  </div>
</template>

<script>
import mFooter from '../components/mFooter';
export default {
  components:{
    mFooter
  },
  name: 'mExplain',
  data () {
    return {
    }
  },
  created() {
    this.setPageSize();
  },
  methods: {
    setPageSize() {
      !function(e,t){function n(){t.body?t.body.style.fontSize=12*o+"px":t.addEventListener("DOMContentLoaded",n)}function d(){var e=i.clientWidth/10;i.style.fontSize=e+"px"}var i=t.documentElement,o=e.devicePixelRatio||1;if(n(),d(),e.addEventListener("resize",d),e.addEventListener("pageshow",function(e){e.persisted&&d()}),o>=2){var a=t.createElement("body"),s=t.createElement("div");s.style.border=".5px solid transparent",a.appendChild(s),i.appendChild(a),1===s.offsetHeight&&i.classList.add("hairlines"),i.removeChild(a)}}(window,document);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.mExplain {
  width: 100vw;
  height: auto;
}
.explainImg {
  margin: 0 auto;
  width: 100vw;
  max-width: 750px;
  height: auto;
}
</style>
